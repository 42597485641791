<template>
  <base-layout-two page-title="Resource" page-default-back-link="/resources" end-button-text="Edit"
    :end-button-url="`/resources/${$route.params.id}/edit`">
    <section class="ion-padding background-white">
      <h1>{{ resource.name }}</h1>
      <p>{{ resource.description_short }}</p>

    </section>

    <div class="padding-8">
      <base-card title="Resource Details">
        <base-field-display label-text="URL">
          <p>{{ resource.url }}</p>
        </base-field-display>

        <base-field-display label-text="Category">
          <p>{{ resource.category }}</p>
        </base-field-display>

        <base-field-display label-text="Location">
          <p>{{ resource.location }}</p>
        </base-field-display>

        <base-field-display label-text="Resource Description">
          <p>{{ resource.description }}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
export default {
  data() {
    return {
      resource: {},
    };
  },

  ionViewWillEnter() {
    this.fetchResource();
  },

  methods: {
    async fetchResource() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/resources/${this.$route.params.id}`)
        .then((response) => {
          this.resource = response.data.resource;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>